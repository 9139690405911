class CTVA {
  constructor () {
    this.base_url = window.CTVA_API_BASE_URL || this.getScriptDomain('va.js')
  }

  getScriptDomain (name) {
    let scripts = document.getElementsByTagName('script'),
      len = scripts.length,
      re = RegExp(`(https?://[^/]+)[^?]+/${name.replace('.','\\.')}($|\\?)`),
      src,
      m

    while (len--) {
      if ((src = scripts[len].src) && (m = src.match(re))) {
        return m[1]
      }
    }
  }

  event (name, params) {
    params.document_location = document.location.href

    if (document.referrer) {
      params.document_referrer = document.referrer
    }

    return fetch(`${this.base_url}/api/v1/events/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        events: [
          {
            event: name,
            params: params
          }
        ]
      })
    })
  }

  page_view () {
    const dpr = window.devicePixelRatio ? window.devicePixelRatio : 1
    let data = {
      tz: new Date().getTimezoneOffset(),
      width: screen.width !== undefined ? Math.floor(screen.width * dpr) : 0,
      height: screen.height !== undefined ? Math.floor(screen.height * dpr) : 0,
      popup: !!window.opener,
      frame: window.top !== window.self,
    }
    return this.event('page_view', data)
  }
}

window.CTVA = new CTVA()
